import React, { useEffect, useState, useRef } from 'react'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'
import { connectApp } from './store/app.connect'
import {
  clearIntervalAsync,
  setIntervalAsync,
} from 'set-interval-async/dynamic'
import {
  ID_TOKEN,
  USER_DATA,
  TOKEN_EXP,
  GET_BALANCE_INTERVAL,
  GET_MESSAGE_INTERVAL,
} from './config/app.config'
import {
  IonApp,
  IonRouterOutlet,
  IonHeader,
  withIonLifeCycle,
} from '@ionic/react'
import { createMemoryHistory } from 'history'
import { IonReactMemoryRouter, IonReactRouter } from '@ionic/react-router'
import { menuController } from '@ionic/core'
import Draggable from 'react-draggable'

import Login from './pages/Login/Login'
import DefaultPage from './pages/DefaultPage/DefaultPage'
import MyMarkets from './pages/MyMarkets/MyMarkets'
import Search from './pages/Search/Search'
import SessionPopover from './components/SessionPopover/SessionPopover'
import BetPlacingPopover from './components/BetPlacingPopover/BetPlacingPopover'
import LeftSideMenu from './components/LeftSideMenu/LeftSideMenu'
import RightSideMenu from './components/RightSideMenu/RightSideMenu'
import RulesRegs from './pages/RulesRegs/RulesRegs'
import ChangePassword from './pages/ChangePassword/ChangePassword'
import TimeSettings from './pages/TimeSettings/TimeSettings'
import Settings from './pages/Settings/Settings'
import MenuEvent from './pages/MenuEvent'
import Competition from './pages/Competitions'
import ResponseGambling from './pages/ResponsibleGambling'
import ExclusionPolicy from './pages/ExclusionPolicy'
import PrivacyPolicy from './pages/PrivacyPolicy'
// import MyMarketsFab from './components/MyMarketsFab/MyMarketsFab'
import BalanceInformation from './components/BalanceInformation/BalanceInformation'
import BetSlipDesktop from './components/BetSlipDesktop/BetSlipDesktop'
import { BetPopoverContext } from './context'
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import './App.scss'
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'
/* Theme variables */
import './_colorScheme.scss'
import BettingProfitAndLosePage from './pages/BettingProfitAndLossPage/BettingProfitAndLossPage'
import BettingProfitAndLossDetailsPage from './pages/BettingProfitAndLoseDetailsPage/BettingProfitAndLossDetailsPage'
import TransferStatementPage from './pages/TransferStatementPage/TransferStatementPage'
import EventPage from './pages/EventPage/EventPage'
import KycPage from './pages/KycPage/KycPage'
import MyOpenBets from './pages/MyOpenBets/MyOpenBets'
import MyCasinoBets from './pages/MyCasinoBets/MyCasinoBets'
import RulesPage from './pages/RulesPage/RulesPage'
import CasinoGames from './pages/CasinoGames/CasinoGames'
import CasinoLive from './pages/CasinoLive/CasinoLive'
import LiveCasinoAndSlotPage from './pages/LiveCasino&SlotPage/LiveCasino&SlotPage'
import Sportbook from './pages/Sportbook/Sportbook'
import Scorecard from './pages/Scorecard/Scorecard'
import AccountStatementPage from './pages/AccountStatementPage/AccountStatementPage'
import AccountStatementDetailsPage from './pages/AccountStatementDetailsPage/AccountStatementDetailsPage'
import * as Sentry from '@sentry/react'
import Deposit from './pages/Deposit/Deposit'
import Transactions from './pages/Transactions/Transactions'
import DetailedDepositTransaction from './pages/DetailedDepositTransaction/DetailedDepositTransaction'
import DepositTimer from './pages/DepositTimer/DepositTimer'
import Withdrawal from './pages/Withdrawal/Withdrawal'
import ThanksWithdrawal from './pages/ThanksWithdrawal/ThanksWithdrawal'
import TermsOfWithdrowalPayment from './pages/TermsOfWithdrowalPayment/TermsOfWithdrowalPayment'
import ToolbarDesktop from './components/ToolbarDesktop/ToolbarDesktop'
import FavouritesPage from './pages/FavouritesPage/FavouritesPage'
import SearchDesktop from './pages/SearchDesktop/SearchDesktop'
import EditStakesPopUp from './components/BetSlipDesktop/EditStakesPopUp/EditStakesPopUp'
import { useDispatch, useSelector } from 'react-redux'
import LeftSideMenuDesktop from './components/LeftSideMenuDesktop/LeftSideMenuDesktop'
import SportsbookNotAuth from './pages/SportbookNotAuth/SportbookNotAuth'

import ConfirmBetPopUp from './components/DesktopBetPlacing/ConfirmBetPopup/ConfirmBetPopUp'
import SportsPage from './pages/SportsPage/SportsPage'
import DesktopLogin from './pages/DesktopLogin/DesktopLogin'
import FooterDesktop from './components/FooterDesktop/FooterDesktop'
import RacingPage from './pages/RacingPage/RacingPage'
import ExchangeGames from './pages/ExchangeGames/ExchangeGames'
import ExchangeGamesMobile from './pages/ExchangeGamesMobile/ExchangeGamesMobile'
import ExchangeGamePage from './pages/ExchangeGamePage/ExchangeGamePage'
import LiveCasinoLobby from './pages/LiveCasinoLobby/LiveCasinoLobby'
import LoginPopUp from './components/LoginPopUp'
import BonusPopUp from './components/BonusPopUp'
import SlotsGamesLobby from './pages/SlotsGamesLobby/SlotsGamesLobby'
import { BASE_DEV_URL, CONFIG, hosts, IS_B2C } from './config/api.config'
import { setReq } from './store/deposit/deposit.slice'
import BonusStatementPage from './pages/BonusStatementPage/BonusStatementPage'
import axios from 'axios'
// import LastResults from './components/ExchangeGame/helpers/LastResults/LastResults'
import RulesPopUp from './components/ExchangeGame/helpers/Rules/RulesPopUp'
import whatsAppImg from '../src/assets/images/wp_support.png'
import { createPortal } from 'react-dom'
import DepositTimerQr from './pages/DepositTimerQr/DepositTimerQr'
import ErrorPopUp from './components/ErrorHandler/ErrorPopUp'
import Bonuses from './pages/bonuses/Bonuses'
import ReactGA from 'react-ga4'
import Profile from './pages/Profile/Profile'
import CasinoProvider from './pages/CasinoProvider/CasinoProvider'
import AccountStatementCasinoDetailsPage from './pages/AccountStatementCasinoDetailsPage/AccountStatementCasinoDetailsPage'
import BettingProfitAndLossCasinoDetailsPage from './pages/BettingProfitAndLoseCasinoDetailsPage/BettingProfitAndLossCasinoDetailsPage'
import MyPastBets from './pages/MyPastBets/MyPastBets'

const modalRoot = document.querySelector('#modal-root')

const presetPage =
  localStorage.getItem('page') && localStorage.getItem('pageStack')
// const presetPageStack = presetPage ? JSON.parse(localStorage.getItem('pageStack')) : ['/']
const presetPageStack = presetPage
  ? JSON.parse(localStorage.getItem('pageStack'))
  : ['/default-page']
const history = createMemoryHistory({
  initialEntries: presetPageStack,
  initialIndex: presetPageStack.length - 1,
})

const App = ({
  actions,
  auth,
  authData,
  user,
  balance,
  balanceSettings,
  menu,
  message,
  continueSessionModalShow,
  showBetPlacingPopover,
  lastActivityDate,
  loginNew,
  balanceInformationHeight,
  isPopUpOpenDispatch,
  isConfirmBetPopUpOpen,
  rulesPopUp,
  elementsParams,
  version,
  versionPopUp,
  state,
  promotions,
}) => {
  const [timeCounter, setTimeCounter] = useState(0)
  const [scrollPosition, setScrollPosition] = useState(0)
  const appRef = useRef(null)
  const refBetPopover = useRef(null)
  const refSessionPopover = useRef(null)
  const bonusPopUp = useSelector((state) => state.bonusPopUp.isPopUpOpen)
  const dispatch = useDispatch()
  const [animApp, setAnimApp] = useState(false)
  const [animAppOpen, setAnimAppOpen] = useState(false)
  const [animAppClose, setAnimAppClose] = useState(false)
  const [isWhatsappOpen, setIsWhatsappOpen] = useState(true)

  //Needed for Ionic re-renderer (betbyWidget and Sportsbook)
  const [localPathnameNow, setLocalPathnameNow] = useState()
  // const [isOnline, setIsOnline] = useState(navigator.onLine);

  // Update the state when the connection status changes
  const updateOnlineStatus = () => {
    // setIsOnline(navigator.onLine);
    if (navigator.onLine) {
      // Navigate to the homepage when back online
      history.push('/default-page')
    } else {
      // Navigate to an offline page or show a message when offline
      // history.push('/offline');
    }
  }

  useEffect(() => {
    // Listen for online and offline events
    window.addEventListener('online', updateOnlineStatus)
    window.addEventListener('offline', updateOnlineStatus)

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('online', updateOnlineStatus)
      window.removeEventListener('offline', updateOnlineStatus)
    }
  }, [])

  // localStorage.setItem('waId', window.location.href.split('=')[1])

  // useEffect(() => {
  //   if (window.location.href?.includes('waId')) {
  //     localStorage.setItem('waId', window.location.href.split('=')[1])
  //     actions.whatsAppReq(localStorage.getItem('waId'))
  //   }
  //   if (window.location.href?.includes('verify')) {
  //     const memberCode = window.location.href
  //       ?.split('email/')?.[1]
  //       ?.split('/')?.[0]
  //     const verifyToken = window.location.href
  //       ?.split('email/')?.[1]
  //       ?.split('/')?.[1]
  //     console.log('START VERIFY IN HREF', memberCode, verifyToken)
  //     actions.confirmMailVerification(memberCode, verifyToken)
  //   }
  // }, [window.location.href])

  useEffect(() => {
    try {
      const localToken = localStorage.getItem(`${ID_TOKEN}`)
      const localTokenExp = localStorage.getItem(`${TOKEN_EXP}`)
      const localUser = localStorage.getItem(`${USER_DATA}`)
      // If app has been F5ed  - get all app data again
      // Otherwise log user out
      if (localToken && localUser && localTokenExp) {
        // set local token in store

        axios
          .get(BASE_DEV_URL + `user/validateToken`, {
            headers: {
              Authorization: `Bearer ${localToken}`,
            },
          })
          .then((response) => {
            if (response?.data.status) {
              actions.getAuthSuccess(localToken)
              actions.setUserDataSuccess(JSON.parse(localUser))
              actions.setAuthDataSuccess({ payload: { exp: localTokenExp } })
            } else {
            }
          })
      } else {
        // localStorage.setItem('page', '/default-page')
        // localStorage.setItem('page', '/')
        //actions.requestLogout()
      }

      let urlParams = new URLSearchParams(window.location.search)
      if (urlParams?.has('txnId')) {
        const txnId = urlParams?.get('txnId')
        updatePgDepositRequest(txnId, urlParams)
      } else {
        // history.push('/default-page')
      }
    } catch (error) {
      // localStorage.setItem('page', '/default-page')
      actions.requestLogout()
    }

    actions.setBannerPopUp(true)

    setTimeout(() => {
      setAnimApp(true)
      setAnimAppOpen(true)
      setTimeout(() => {
        setAnimApp(false)
        setAnimAppClose(true)
        setAnimAppOpen(false)
      }, 20000)
    }, 6500)

    const unlisten = history.listen(({ action, location }) => {
      menuController.close()

      localStorage.setItem('page', history.location.pathname)
      localStorage.setItem('pageStack', JSON.stringify(history.entries))
    })
    // on Page Unload return to default Page
    window.onunload = function () {
      // history.push("/default-page");
    }

    // try {
    //   // set cookies if have locationSearch
    //   if (window.location.search) {
    //     let s = '='
    //     const queryString = window.location.search
    //     const parameters = new URLSearchParams(queryString)
    //     parameters.forEach((value, key, urlParam) => {
    //       if (key === 'atag') {
    //         Cookies.set(`${key}`, `${value}`, { expires: 60 })
    //         localStorage.setItem(`${key}`, `${value}`)
    //       }
    //     })
    //   }

    //   if (Cookies.get('atag')) {
    //     localStorage.setItem('atag', Cookies.get('atag'))
    //   }
    // } catch (e) {
    //   console.log(e)
    // }
    actions.requestProfile()

    return () => {
      unlisten()
      window.onunload = null
    }
  }, [])

  // const renderFab = () => {
  //   if (!auth) {
  //     return createPortal(
  //       <div className="whatsApp" onClick={() => window.open(`${CONFIG.whatsappLink}`)}>
  //         <img className="whatsApp_img" src={whatsAppImg} alt="" />
  //         <div className={`circleIconPanel ${animAppOpen ? "showMessage" : animAppClose ? "hideMessage" : ""}  `}></div>
  //       </div>, modalRoot
  //     );
  //   }
  // };

  const renderFab = () => {
    if (!auth) {
      return createPortal(
        <div
          className="whatsApp"
          onClick={() => window.open(`${CONFIG.whatsappLink}`)}
        >
          <img
            loading={'lazy'}
            className="whatsApp_img"
            src={whatsAppImg}
            alt=""
          />
          <div
            className={`circleIconPanel ${
              animAppOpen ? 'showMessage' : animAppClose ? 'hideMessage' : ''
            }  `}
          ></div>
        </div>,
        modalRoot
      )
    }
  }

  useEffect(() => {
    try {
      let link = document.querySelector("link[rel~='icon']")
      if (!link) {
        link = document.createElement('link')
        link.rel = 'icon'
        document.getElementsByTagName('head')[0].appendChild(link)
      }
      link.href = CONFIG.favicon
      // document.title = CONFIG.appName;

      // meta descriptions
      // const meta = document.createElement("meta");
      // meta.name = "description";
      // meta.content = CONFIG.metaDescription;
      // document.getElementsByTagName("head")[0].appendChild(meta);

      // Variable CSS
      const variableCss = document.createElement('link')
      variableCss.rel = 'stylesheet'
      variableCss.type = 'text/css'
      variableCss.href = CONFIG.variableCss

      document.getElementsByTagName('head')[0].appendChild(variableCss)

      const lmtTheme = document.createElement('link')
      lmtTheme.rel = 'stylesheet'
      lmtTheme.type = 'text/css'
      lmtTheme.href = CONFIG.lmtTheme
      document.getElementsByTagName('head')[0].appendChild(lmtTheme)

      // Apple-touch-icon
      // const appleTouchIcon = document.createElement('link')
      // appleTouchIcon.rel = 'apple-touch-icon'
      // appleTouchIcon.href = CONFIG.appleTouchIcon
      // document.getElementsByTagName('head')[0].appendChild(appleTouchIcon)

      //   <meta name="description" content="Lotus App" />
      //   <link rel=”mask-icon” href="%PUBLIC_URL%/logo.svg" color="#000000">
      //   <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo180.png" />
      //   <link href="%PUBLIC_URL%/manifest.json" rel="manifest" />
      const manifest = document.createElement('link')
      manifest.rel = 'manifest'
      manifest.href = CONFIG.manifestUrl
      document.getElementsByTagName('head')[0].appendChild(manifest)

      // Google Analytics
      // const js = document.createElement("script");
      // js.type = "text/javascript";
      // js.src =
      //   "https://www.googletagmanager.com/gtag/js?id=" + CONFIG.googleAnalytics;
      // // console.log('loading script')

      // window.addEventListener("load", function() {
      //   // console.log('loading done')

      //   //Code using this script here
      //   window.dataLayer = window.dataLayer || [];

      //   function gtag() {
      //     window.dataLayer.push(arguments);
      //   }

      //   gtag("js", new Date());

      //   gtag("config", CONFIG.googleAnalytics);
      // });

      //OneSignal init

      // console.log("OneSignal init with Id: ", CONFIG.oneSignalAppiD);
      // OneSignal.init({
      //   appId: CONFIG.oneSignalAppiD,
      //   safari_web_id: CONFIG.oneSignalSafariWebID,
      //   notifyButton: {
      //     enable: true
      //   }
      // }).then(() => {
      //   console.log("OneSignal init done");
      //   OneSignal.showSlidedownPrompt().then(() => {
      //     console.log("OneSignalPrompt shown");
      //   });
      // });
    } catch (e) {
      console.log(e)
    }
    // }
  }, [CONFIG, hosts])

  useEffect(() => {
    if (!authData || !auth) return
    else {
      const date = Date.now()
      // if (date >= authData.payload.exp * 1000 - KEEP_ALIVE_POPUP) {
      // else if (date >= authData.payload.exp * 1000 - 3580000) {
      // if (!continueSessionModalShow) {
      //   actions.setContinueSessionModalShow(false)
      // }
      // }
      const timer = setInterval(() => {
        setTimeCounter((timeCounter) => timeCounter + 1)
      }, 1000)
      return () => clearInterval(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, authData, timeCounter])

  useEffect(() => {
    if (!auth || !user) return
    if (!balance) getBalance()
    else {
      const timer = setIntervalAsync(async () => {
        await actions
          .requestBalance(user.memberCode)
          .catch((e) => console.log('balance error = ', e))
      }, GET_BALANCE_INTERVAL)
      return () => clearIntervalAsync(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, balance])

  useEffect(() => {
    // if (!auth || !user) return
    //TODO: menu call change for each sport

    // data for menu items
    const cricket = { sport_id: '4' }
    const tennis = { sport_id: '2' }
    const soccer = { sport_id: '1' }

    // if (!menu) getMenu(cricket);
    // interval stopped for now
    // else {
    //   const timer = setIntervalAsync(async () => {
    //     await actions.requestMenu().catch((e) => console.log("menu error = ", e));
    //   }, GET_MENU_INTERVAL);
    //   return () => clearIntervalAsync(timer);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, menu])

  // useEffect(() => {
  //   if (!auth || !user) return;
  //   if (!balanceSettings) getBalanceSettings();
  //   const timer = setIntervalAsync(async () => {
  //     await actions
  //       .requestBalanceSettings(user.memberCode)
  //       .catch((e) => console.log("BalanceSettings error = ", e));
  //   }, GET_BALANCE_SETTINGS_INTERVAL);
  //   return () => clearIntervalAsync(timer);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [auth, balanceSettings]);
  // console.log('!! App refRecaptcha', refRecaptcha)

  useEffect(() => {
    // if (!auth || !user) return
    if (!message && auth) getMessage()
    else {
      const timer = setIntervalAsync(async () => {
        await actions
          .requestMessage(user.memberCode)
          .catch((e) => console.log('message error = ', e))
      }, GET_MESSAGE_INTERVAL)
      return () => clearIntervalAsync(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, message])

  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    if (width > 1024) {
      if (localStorage.getItem('page')) {
        setLocalPathnameNow(localStorage.getItem('page'))
      } else {
        setLocalPathnameNow('/default-page')
      }
    }
  }, [localStorage.getItem('page')])

  useEffect(() => {
    // if (auth) actions.requestOpenBets();
  }, [lastActivityDate, auth])

  useEffect(() => {
    if (width !== elementsParams?.clientWidth)
      actions.setClientWidthThunk(width)

    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth
      setWidth(newWidth)
      actions.setClientWidthThunk(newWidth)
    }
    window.addEventListener('resize', updateWindowDimensions)

    return () => window.removeEventListener('resize', updateWindowDimensions)
  }, [])

  // loginPopUp
  useEffect(() => {
    let isLogedOut = localStorage.getItem('isLogedOut')
    let check = isLogedOut ? JSON.parse(isLogedOut) : false
    // console.log("Hitting ", auth, isLogedOut)
    if (!auth && history.location.pathname != '/default-page') {
      history.push('/default-page')
    } else if (!auth && check === true) {
      actions.setLoginNew(true)
      localStorage.setItem('isLogedOut', false)
    } else {
      actions.setLoginNew(false)
    }
  }, [auth])

  useEffect(() => {
    appRef.current.scrollIntoView({
      block: 'start',
      inline: 'start',
    })
    // window.scrollTo({top: 10})
  }, [rulesPopUp])

  // update pop-up
  // const [isVersionPopUpOpen, setIsVersionPopUpOpen] = useState(false);
  // console.log(version)
  // useEffect(() => {
  //   let versionNow = version;

  //   if (version) {
  //     if (localStorage.getItem("versionNow") === versionNow) {
  //       return console.log("version up to date");
  //     } else if (!localStorage.getItem("versionNow")) {
  //       localStorage.setItem("versionNow", versionNow);
  //     } else if (localStorage.getItem("versionNow") != versionNow) {
  //       setIsVersionPopUpOpen(true);

  //       localStorage.setItem("versionNow", versionNow);
  //     }
  //   }
  // }, [version]);
  // end update pop-up

  const [renderFooter, setRenderFooter] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setRenderFooter(true)
    }, 1500)
  }, [])

  //get main container height and check if it changed
  const mainContainerRef = useRef(null)
  useEffect(() => {
    actions.mainContainerHeightThunk(
      width > 1024
        ? mainContainerRef?.current?.offsetHeight +
            mainContainerRef?.current?.offsetTop
        : ''
    )
  }, [
    mainContainerRef
      ? mainContainerRef?.current?.offsetHeight
      : localPathnameNow,
  ])
  //end get main container height

  const updatePgDepositRequest = async (id, urlParams) => {
    // console.log('request resent', id)
    if (id) {
      return await axios
        .get(BASE_DEV_URL + `/pg/get_pg_txn_details?transactionId=${id}`, {
          headers: {
            Authorization: localStorage.getItem(`${ID_TOKEN}`),
          },
        })
        .then((response) => {
          let depositReq = localStorage.getItem(id)

          if (!response?.data.success) {
            history.push('/default-page')
          } else if (!depositReq) {
            history.push('/default-page')
          } else {
            urlParams.delete('txnId')
            dispatch(setReq(JSON.parse(depositReq)))
            localStorage.removeItem(id)
            history.push('/depositTimer')
          }
        })
    }
  }

  const getMessage = () => {
    actions
      .requestMessage(user.memberCode)
      .catch((e) => console.log('message error = ', e))
  }

  const getBalance = () => {
    actions
      .requestBalance(user.memberCode)
      .catch((e) => console.log('balance error = ', e))
  }

  const getMenu = (data) => {
    actions.requestMenu(data).catch((e) => console.log('menu error = ', e))
  }

  const getBalanceSettings = () => {
    actions
      .requestBalanceSettings(user.memberCode)
      .catch((e) => console.log('BalanceSettings error = ', e))
  }

  const findPartOfRoute = (route, array) => {
    const res = array.filter((item) => route.indexOf(item) !== -1)
    return !!res.length
  }

  // const renderMyMarketsFab = () =>{
  // (
  //     <MyMarketsFab
  //       click={() => {
  //         history.push('/my_markets')
  //       }}
  //     />
  //   )
  //     }

  const authRender = (Component) =>
    !auth || auth ? Component : width < 1024 ? <Login /> : <DesktopLogin />
  const cancelBetPopover = () => {
    if (refBetPopover.current) {
      refBetPopover.current.dismiss()
    }
    setTimeout(() => {
      if (refBetPopover.current) {
        refBetPopover.current.dismiss()
      } else {
        actions.setShowBetPlacingPopover(false)
      }
    }, 250)
  }
  const cancelSessionPopover = () => {
    if (refSessionPopover.current) {
      refSessionPopover.current.dismiss()
    }
    setTimeout(() => {
      if (refSessionPopover.current) {
        refSessionPopover.current.dismiss()
      } else {
        actions.setContinueSessionModalShow(false)
      }
    }, 250)
  }

  const closeLoginPopUp = () => {
    actions.setLoginNew(false)
  }

  const fullScreenPages =
    localPathnameNow === '/transactions' ||
    localPathnameNow === '/withdrawal' ||
    localPathnameNow === '/deposit' ||
    localPathnameNow === '/exchange-games' ||
    localPathnameNow === '/livecasino-lobby' ||
    localPathnameNow === '/slotsgames-lobby' ||
    localPathnameNow === '/sportsbook/1444005' ||
    localPathnameNow === '/termsOfWithdrowalPayment' ||
    history.location.pathname.includes('/live_casino_slot/') ||
    history.location.pathname.includes('/casino_live') ||
    history.location.pathname.indexOf('competition') !== -1 ||
    history.location.pathname.indexOf('menu-event') !== -1 ||
    history.location.pathname.indexOf('scorecard') !== -1 ||
    localPathnameNow === '/thanksWithdrawal'
      ? true
      : false

  const desktopWith2Container =
    localPathnameNow !== '/withdrawal' &&
    localPathnameNow !== '/deposit' &&
    localPathnameNow !== '/betting_profit_and_loss' &&
    localPathnameNow !== '/my-open-bets' &&
    localPathnameNow !== '/my-past-bets' &&
    localPathnameNow !== '/my-casino-bets' &&
    localPathnameNow !== '/account_statement' &&
    localPathnameNow !== '/transfer_statement' &&
    localPathnameNow !== '/change_password' &&
    localPathnameNow !== '/transactions' &&
    localPathnameNow !== '/thanksWithdrawal' &&
    localPathnameNow !== '/termsOfWithdrowalPayment' &&
    localPathnameNow !== '/rules_regs' &&
    localPathnameNow !== '/about' &&
    localPathnameNow !== '/privacy_policy' &&
    localPathnameNow !== '/searchDesktop' &&
    localPathnameNow !== '/bonuses'
      ? // && localPathnameNow !== "/exchange-games"
        false
      : true

  const B2C_layout =
    localPathnameNow === '/transactions' ||
    localPathnameNow === '/withdrawal' ||
    localPathnameNow === '/deposit' ||
    localPathnameNow == '/termsOfWithdrowalPayment' ||
    localPathnameNow === '/thanksWithdrawal' ||
    localPathnameNow === '/bonuses'
      ? true
      : false

  // Temporary added for development !!!

  const [openErrorPopUp, setOpenErrorPopUp] = useState(false)

  window.onerror = async (message, file, line, col, error) => {
    try {
      console.log('CRASH DETECTED ' + file + ':' + line + ':' + col)
      console.log('CRASH DETECTED', message)
      console.log('error', error)
      // setOpenErrorPopUp(true);
      const userIP = await fetch('https://api.ipify.org/?format=json').then(
        (res) => res.json()
      )
      if (message !== 'ResizeObserver loop limit exceeded') {
        Sentry.captureMessage(
          `IMPORTANT ${window.location.host} ${localStorage.getItem(
            'page'
          )} ${localStorage.getItem('versionNow')} userIP:${userIP?.ip} ` +
            error
        )
        // window.location.reload();
        // localStorage.removeItem("pageStack")
        // localStorage.removeItem("page")
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    let params = new URL(document.location.toString()).searchParams
    let name = params.get('referCode')
    if (name && !auth && IS_B2C) {
      actions.setLoginNew(true)
      actions.setTab(2)
    }
  }, [auth])

  const [isDragging, setIsDragging] = useState(false)
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [longPressStarted, setLongPressStarted] = useState(false)
  const pressTimer = useRef(null)
  const bodyClassAdded = useRef(false)

  const handleDragStart = () => {
    if (longPressStarted) {
      setTimeout(() => {
        setIsDragging(true)
      }, 200)

      if (!bodyClassAdded.current) {
        document.body.classList.add('react-draggable-transparent-selection')
        bodyClassAdded.current = true
      }
    }
  }

  const handleDragStop = (e, data) => {
    setTimeout(() => {
      setIsDragging(false)
    }, 200)

    setLongPressStarted(false)
    if (pressTimer.current) {
      clearTimeout(pressTimer.current)
    }

    document.body.classList.remove('react-draggable-transparent-selection')
    bodyClassAdded.current = false

    setPosition({ x: data.x, y: data.y })
  }

  const handleDrag = (e, data) => {
    setPosition({ x: data.x, y: data.y })
  }

  const handlePressStart = () => {
    pressTimer.current = setTimeout(() => {
      setLongPressStarted(true)
    }, 500)
  }

  const handlePressEnd = () => {
    if (pressTimer.current) {
      clearTimeout(pressTimer.current)
    }
  }

  return (
    <IonApp
      ref={appRef}
      onScroll={() => {
        setScrollPosition(appRef?.current?.scrollTop)
        actions.scrollPositionThunk(appRef?.current?.scrollTop)
      }}
      className={`${continueSessionModalShow ? '' : 'hideSesPopover'} 
      ${
        loginNew ||
        isConfirmBetPopUpOpen ||
        continueSessionModalShow ||
        rulesPopUp ||
        bonusPopUp ||
        isPopUpOpenDispatch
          ? 'disable-events'
          : ''
      }`}
    >
      <BetPopoverContext.Provider value={cancelBetPopover}>
        {/* <IonReactMemoryRouter history={history}></IonReactMemoryRouter> */}
        <Router>
          {width < 1024 ? (
            <>
              <IonRouterOutlet
                id="LeftSide"
                mode="md"
                className="rou222ter-outlet"
              >
                <Route
                  exact
                  path="/search"
                  render={() => authRender(<Search />)}
                />
                <Route
                  exact
                  path="/exchange-games"
                  render={() => authRender(<ExchangeGamesMobile />)}
                />
                <Route
                  exact
                  path="/betting_profit_and_loss"
                  render={() => authRender(<BettingProfitAndLosePage />)}
                />
                <Route
                  exact
                  path="/transfer_statement"
                  render={() => authRender(<TransferStatementPage />)}
                />
                <Route
                  exact
                  path="/account_statement"
                  render={() => authRender(<AccountStatementPage />)}
                />
                <Route
                  exact
                  path="/betting_profit_and_loss_details"
                  render={() => authRender(<BettingProfitAndLossDetailsPage />)}
                />
                <Route
                  exact
                  path="/betting_profit_and_loss_casino_details"
                  render={() =>
                    authRender(<BettingProfitAndLossCasinoDetailsPage />)
                  }
                />
                <Route
                  exact
                  path="/account_statement_details"
                  render={() => authRender(<AccountStatementDetailsPage />)}
                />
                <Route
                  exact
                  path="/account_statement_Casino_details"
                  render={() =>
                    authRender(<AccountStatementCasinoDetailsPage />)
                  }
                />
                <Route
                  exact
                  path="/settings"
                  render={() => authRender(<Settings />)}
                />
                <Route
                  exact
                  path="/time_settings"
                  render={() => authRender(<TimeSettings />)}
                />
                <Route
                  exact
                  path="/change_password"
                  render={() => authRender(<ChangePassword />)}
                />
                <Route
                  exact
                  path="/competition/:eventTypeId/:competitionId"
                  render={() => authRender(<Competition />)}
                />
                <Route
                  exact
                  path="/rules_page/:eventTypeId/:competitionId"
                  render={() => authRender(<RulesPage />)}
                />
                <Route
                  exact
                  path={[
                    '/menu-event/:eventTypeId',
                    '/menu-event/:eventTypeId/:detailId',
                  ]}
                  render={() => authRender(<MenuEvent />)}
                />
                <Route
                  exact
                  path="/event-page/:eventTypeId/:eventId"
                  render={() => authRender(<EventPage />)}
                />
                <Route
                  exact
                  path="/exchange-game-page/:eventTypeId/:eventId"
                  render={() => authRender(<ExchangeGamePage />)}
                />
                <Route
                  exact
                  path="/scorecard/:eventTypeId/:eventId"
                  render={() => authRender(<Scorecard />)}
                />
                <Route
                  exact
                  path="/casino_games"
                  render={() => authRender(<CasinoGames />)}
                />
                <Route
                  exact
                  path="/qtech-casino"
                  render={() => authRender(<CasinoProvider />)}
                />
                <Route
                  exact
                  path={[
                    '/casino_live/:casinoId',
                    '/casino_live/:casinoId/:typeId',
                  ]}
                  render={() =>
                    authRender(
                      <CasinoLive localPathnameNow={localPathnameNow} />
                    )
                  }
                />
                <Route
                  exact
                  path={[
                    '/live_casino_slot/:casinoId',
                    '/live_casino_slot/:casinoId/:typeId',
                  ]}
                  render={() =>
                    authRender(
                      <LiveCasinoAndSlotPage
                        localPathnameNow={localPathnameNow}
                      />
                    )
                  }
                />
                <Route
                  exact
                  path="/sportsbook/:casinoId"
                  render={() =>
                    authRender(
                      auth ? (
                        <Sportbook localPathnameNow={localPathnameNow} />
                      ) : (
                        <SportsbookNotAuth
                          localPathnameNow={localPathnameNow}
                        />
                      )
                    )
                  }
                />
                <Route
                  exact
                  path="/my_markets"
                  render={() => authRender(<MyMarkets />)}
                />
                <Route
                  exact
                  path="/my-open-bets"
                  render={() => authRender(<MyOpenBets />)}
                />
                <Route
                  exact
                  path="/my-past-bets"
                  render={() => authRender(<MyPastBets />)}
                />
                <Route
                  exact
                  path="/my-casino-bets"
                  render={() => authRender(<MyCasinoBets />)}
                />
                <Route
                  exact
                  path="/privacy_policy"
                  render={() => authRender(<PrivacyPolicy />)}
                />
                <Route
                  exact
                  path="/responsible_gambling"
                  render={() => authRender(<ResponseGambling />)}
                />
                <Route
                  exact
                  path="/exclusion_policy"
                  render={() => authRender(<ExclusionPolicy />)}
                />
                <Route
                  exact
                  path="/bonus_statement"
                  render={() => authRender(<BonusStatementPage />)}
                />
                <Route
                  exact
                  path="/kyc"
                  render={() => authRender(<KycPage />)}
                />
                <Route
                  exact
                  path="/rules_regs"
                  render={() => authRender(<RulesRegs />)}
                />
                <Route
                  exact
                  path="/deposit"
                  render={() => authRender(<Deposit />)}
                />
                <Route
                  exact
                  path="/transactions"
                  render={() => authRender(<Transactions />)}
                />
                <Route
                  exact
                  path="/detailedDepositTransaction"
                  render={() => authRender(<DetailedDepositTransaction />)}
                />
                <Route
                  exact
                  path="/withdrawal"
                  render={() => authRender(<Withdrawal />)}
                />
                <Route
                  exact
                  path="/depositTimer"
                  render={() => authRender(<DepositTimer />)}
                />
                <Route
                  exact
                  path="/depositTimerQr"
                  render={() => authRender(<DepositTimerQr />)}
                />
                <Route
                  exact
                  path="/thanksWithdrawal"
                  render={() => authRender(<ThanksWithdrawal />)}
                />
                <Route
                  exact
                  path="/termsOfWithdrowalPayment"
                  render={() => authRender(<TermsOfWithdrowalPayment />)}
                />
                <Route
                  exact
                  path="/favouritesPage"
                  render={() => authRender(<FavouritesPage />)}
                />
                <Route
                  exact
                  path="/livecasino-lobby"
                  render={() =>
                    authRender(
                      <LiveCasinoLobby localPathnameNow={localPathnameNow} />
                    )
                  }
                />
                <Route
                  exact
                  path="/slotsgames-lobby"
                  render={() =>
                    authRender(
                      <SlotsGamesLobby localPathnameNow={localPathnameNow} />
                    )
                  }
                />
                <Route
                  exact
                  path="/bonus_statement"
                  render={() => authRender(<BonusStatementPage />)}
                />
                <Route
                  exact
                  path="/bonuses"
                  render={() => authRender(<Bonuses />)}
                />
                <Route
                  exact
                  path="/profile"
                  render={() => authRender(<Profile />)}
                />
                <Route
                  exact
                  path="/default-page"
                  render={() =>
                    authRender(
                      <DefaultPage localPathnameNow={localPathnameNow} />
                    )
                  }
                />
                <Redirect
                  to={((location) => {
                    const search = location.search || '' // Retain existing query params
                    return `/default-page${search}`
                  })(window.location)}
                />
              </IonRouterOutlet>
              <LeftSideMenu />
              <RightSideMenu />
            </>
          ) : (
            <>
              {openErrorPopUp && <ErrorPopUp />}
              {(!auth || auth) && (
                <IonHeader>
                  <ToolbarDesktop key="search" search />
                </IonHeader>
              )}
              <div
                className={`main_container ${
                  localStorage.getItem('page') === '/exchange-games'
                    ? 'ExchangeGamesLobby'
                    : ''
                }`}
                onClick={() => {
                  actions.setSearchValues('')
                }}
                style={fullScreenPages ? { maxHeight: '450px' } : {}}
              >
                <div className="main_container-left">
                  <LeftSideMenuDesktop />
                </div>
                <div
                  className={`main_container-center ${
                    desktopWith2Container ? 'main_container-center__big' : ''
                  }  ${B2C_layout ? 'relative' : '11111'} `}
                  ref={mainContainerRef}
                >
                  <Switch>
                    <Route
                      exact
                      path="/searchDesktop"
                      render={() => authRender(<SearchDesktop />)}
                    />
                    <Route
                      exact
                      path="/livecasino-lobby"
                      render={() =>
                        authRender(
                          <LiveCasinoLobby
                            localPathnameNow={localPathnameNow}
                          />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/slotsgames-lobby"
                      render={() =>
                        authRender(
                          <SlotsGamesLobby
                            localPathnameNow={localPathnameNow}
                          />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/exchange-games"
                      render={() =>
                        authRender(
                          <ExchangeGames localPathnameNow={localPathnameNow} />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/betting_profit_and_loss"
                      render={() => authRender(<BettingProfitAndLosePage />)}
                    />
                    <Route
                      exact
                      path="/transfer_statement"
                      render={() => authRender(<TransferStatementPage />)}
                    />
                    <Route
                      exact
                      path="/account_statement"
                      render={() => authRender(<AccountStatementPage />)}
                    />
                    <Route
                      exact
                      path="/betting_profit_and_loss_details"
                      render={() =>
                        authRender(<BettingProfitAndLossDetailsPage />)
                      }
                    />
                    <Route
                      exact
                      path="/betting_profit_and_loss_casino_details"
                      render={() =>
                        authRender(<BettingProfitAndLossCasinoDetailsPage />)
                      }
                    />
                    <Route
                      exact
                      path="/account_statement_details"
                      render={() => authRender(<AccountStatementDetailsPage />)}
                    />
                    <Route
                      exact
                      path="/account_statement_Casino_details"
                      render={() =>
                        authRender(<AccountStatementCasinoDetailsPage />)
                      }
                    />
                    <Route
                      exact
                      path="/settings"
                      render={() => authRender(<Settings />)}
                    />
                    <Route
                      exact
                      path="/time_settings"
                      render={() => authRender(<TimeSettings />)}
                    />
                    <Route
                      exact
                      path="/change_password"
                      render={() => authRender(<ChangePassword />)}
                    />
                    <Route
                      exact
                      path="/competition/:eventTypeId/:competitionId"
                      render={() => authRender(<Competition />)}
                    />
                    <Route
                      exact
                      path="/rules_page/:eventTypeId/:competitionId"
                      render={() => authRender(<RulesPage />)}
                    />
                    <Route
                      exact
                      path={[
                        '/menu-event/:eventTypeId',
                        '/menu-event/:eventTypeId/:detailId',
                      ]}
                      render={() => authRender(<MenuEvent />)}
                    />
                    <Route
                      exact
                      path="/event-page/:eventTypeId/:eventId"
                      render={() => authRender(<EventPage />)}
                    />
                    <Route
                      exact
                      path="/exchange-game-page/:eventTypeId/:eventId"
                      render={() => authRender(<ExchangeGamePage />)}
                    />
                    <Route
                      exact
                      path="/scorecard/:eventTypeId/:eventId"
                      render={() => authRender(<Scorecard />)}
                    />
                    <Route
                      exact
                      path="/casino_games"
                      render={() => authRender(<CasinoGames />)}
                    />
                    <Route
                      exact
                      path="/qtech-casino"
                      render={() => authRender(<CasinoProvider />)}
                    />
                    <Route
                      exact
                      path={[
                        '/casino_live/:casinoId',
                        '/casino_live/:casinoId/:typeId',
                      ]}
                      render={() =>
                        authRender(
                          <CasinoLive localPathnameNow={localPathnameNow} />
                        )
                      }
                    />
                    <Route
                      exact
                      path={[
                        '/live_casino_slot/:casinoId',
                        '/live_casino_slot/:casinoId/:typeId',
                      ]}
                      render={() =>
                        authRender(
                          <LiveCasinoAndSlotPage
                            localPathnameNow={localPathnameNow}
                          />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/sportsbook/:casinoId"
                      render={() =>
                        authRender(
                          auth ? (
                            <Sportbook localPathnameNow={localPathnameNow} />
                          ) : (
                            <SportsbookNotAuth
                              localPathnameNow={localPathnameNow}
                            />
                          )
                        )
                      }
                    />
                    <Route
                      exact
                      path="/my_markets"
                      render={() => authRender(<MyMarkets />)}
                    />
                    <Route
                      exact
                      path="/my-open-bets"
                      render={() => authRender(<MyOpenBets />)}
                    />
                    <Route
                      exact
                      path="/my-past-bets"
                      render={() => authRender(<MyPastBets />)}
                    />
                    <Route
                      exact
                      path="/my-casino-bets"
                      render={() => authRender(<MyCasinoBets />)}
                    />
                    <Route
                      exact
                      path="/responsible_gambling"
                      render={() => authRender(<ResponseGambling />)}
                    />
                    <Route
                      exact
                      path="/exclusion_policy"
                      render={() => authRender(<ExclusionPolicy />)}
                    />
                    <Route
                      exact
                      path="/kyc"
                      render={() => authRender(<KycPage />)}
                    />
                    <Route
                      exact
                      path="/rules_regs"
                      render={() => authRender(<RulesRegs />)}
                    />
                    <Route
                      exact
                      path="/deposit"
                      render={() => authRender(<Deposit />)}
                    />
                    <Route
                      exact
                      path="/transactions"
                      render={() => authRender(<Transactions />)}
                    />
                    <Route
                      exact
                      path="/sports-page"
                      render={() => authRender(<SportsPage />)}
                    />
                    <Route
                      exact
                      path="/racing-sports-page"
                      render={() => authRender(<RacingPage />)}
                    />
                    <Route
                      exact
                      path="/detailedDepositTransaction"
                      render={() => authRender(<DetailedDepositTransaction />)}
                    />
                    <Route
                      exact
                      path="/withdrawal"
                      render={() => authRender(<Withdrawal />)}
                    />
                    <Route
                      exact
                      path="/depositTimer"
                      render={() => authRender(<DepositTimer />)}
                    />
                    <Route
                      exact
                      path="/depositTimerQr"
                      render={() => authRender(<DepositTimerQr />)}
                    />
                    <Route
                      exact
                      path="/thanksWithdrawal"
                      render={() => authRender(<ThanksWithdrawal />)}
                    />
                    <Route
                      exact
                      path="/termsOfWithdrowalPayment"
                      render={() => authRender(<TermsOfWithdrowalPayment />)}
                    />
                    <Route
                      exact
                      path="/favouritesPage"
                      render={() => authRender(<FavouritesPage />)}
                    />
                    <Route
                      exact
                      path="/bonuses"
                      render={() => authRender(<Bonuses />)}
                    />
                    <Route
                      exact
                      path="/default-page"
                      render={() =>
                        authRender(
                          <DefaultPage localPathnameNow={localPathnameNow} />
                        )
                      }
                    />
                    <Redirect to="/default-page" />
                  </Switch>
                  {desktopWith2Container && (
                    <div className="balance-info">
                      <BalanceInformation />
                    </div>
                  )}
                </div>
                {(auth || !auth) &&
                  !desktopWith2Container &&
                  localPathnameNow &&
                  !localPathnameNow.includes('sportsbook') &&
                  !localPathnameNow.includes('casino_live') &&
                  !localPathnameNow.includes('exchange-games') && (
                    <div className="main_container-rigth">
                      <div
                        className="main_container__betslip-desktop_wrapper"
                        style={{ top: `-${balanceInformationHeight}px` }}
                      >
                        <BalanceInformation />
                        {/* {auth && <BetSettingsDesktop />} */}
                        {/*<LiveMatchVideo localPathnameNow={localPathnameNow} />*/}
                        {/* {width > 1024 && <LastResults isGame={false} />} */}
                        <BetSlipDesktop />
                      </div>
                    </div>
                  )}
              </div>
              {isPopUpOpenDispatch && (
                <div
                  className="editStakesPopUp_wrapperr"
                  style={{ top: `${scrollPosition}px`, zIndex: '3' }}
                >
                  <div
                    style={
                      scrollPosition < 170
                        ? { top: `${170 - scrollPosition}px`, zIndex: '3' }
                        : {}
                    }
                    className="editStakesPopUp"
                  >
                    <EditStakesPopUp open={isPopUpOpenDispatch} />
                  </div>
                </div>
              )}
              {(auth || !auth) &&
                localPathnameNow !== '/sportsbook/1444005' &&
                localPathnameNow !== '/exchange-games' &&
                localPathnameNow !== '/livecasino-lobby' &&
                localPathnameNow !== '/slotsgames-lobby' &&
                localPathnameNow !== '/transactions' &&
                localPathnameNow !== '/deposit' &&
                localPathnameNow !== '/withdrawal' &&
                localPathnameNow !== '/termsOfWithdrowalPayment' &&
                localPathnameNow !== '/thanksWithdrawal' &&
                !history.location.pathname.includes('/competition') &&
                !history.location.pathname.includes('/menu-event') &&
                !history.location.pathname.includes('/scorecard') &&
                !history.location.pathname.includes('/live_casino_slot/') &&
                !history.location.pathname.includes('/casino_live') &&
                renderFooter && <FooterDesktop />}
            </>
          )}
          <LoginPopUp
            open={loginNew}
            onDidDismiss={closeLoginPopUp}
            scrollPosition={scrollPosition}
          />
          {/* <UpdatePopUp isOpen={isVersionPopUpOpen} /> */}
          {bonusPopUp && <BonusPopUp scrollPosition={scrollPosition} />}
          <SessionPopover
            cancel={cancelSessionPopover}
            refSessionPopover={refSessionPopover}
            open={continueSessionModalShow}
            scrollPosition={scrollPosition}
          />
          {width < 1024 && showBetPlacingPopover ? (
            <BetPlacingPopover
              cancel={cancelBetPopover}
              refBetPopover={refBetPopover}
              open={showBetPlacingPopover}
            />
          ) : (
            <ConfirmBetPopUp
              cancel={cancelBetPopover}
              refBetPopover={refBetPopover}
              open={showBetPlacingPopover}
            />
          )}
          {width < 1024 && rulesPopUp && <RulesPopUp />}

          {/* {<ReduxToastr
            timeOut={loginNew ? 0 : 35000}
            closeOnToastrClick={false}
            position="top-center"
            transitionIn="bounceInDown"
            transitionOut="bounceOutUp"
          />} */}
          {/* {isPopUpOpenDispatch &&
           <div className='editStakesPopUp'>
            <div className='editStakesPopUp_wrapperr'>
              <EditStakesPopUp/>
            </div>
            } */}
        </Router>
        {/* {bonusPopUp && <BonusPopUp></BonusPopUp>} */}
        {/* {renderMyMarketsFab()} */}
        {/* <MyMarketsFab
        click={() => {
          history.push('/my_markets')
        }}
      />  */}
        {/* {renderFooter && renderFab()} */}
        {promotions && promotions?.Whatsapp && IS_B2C && isWhatsappOpen && (
          <Draggable
            position={position}
            onStart={handleDragStart}
            onDrag={handleDrag}
            onStop={handleDragStop}
            {...(width > 1024 ? {} : { disabled: !longPressStarted })}
          >
            <div
              className="whatsApp"
              style={{
                position: 'absolute',
                cursor: 'grab',
              }}
              onTouchStart={handlePressStart}
              onTouchEnd={handlePressEnd}
              onMouseDown={handlePressStart}
              onMouseUp={handlePressEnd}
              onClick={() =>
                !isDragging ? window.open(`${promotions.Whatsapp.url}`) : {}
              }
            >
              <div
                className="whatsAppClose"
                onClick={() => setIsWhatsappOpen(false)}
              >
                <svg
                  width="31"
                  height="31"
                  viewBox="0 0 31 31"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="15.5" cy="15.5" r="15.5" fill="#142326" />
                  <path
                    d="M11.2045 10.0005C10.9659 10.0008 10.7328 10.0722 10.5349 10.2057C10.3371 10.3391 10.1835 10.5285 10.0938 10.7496C10.0042 10.9707 9.98244 11.2136 10.0314 11.4471C10.0804 11.6806 10.1979 11.8943 10.3689 12.0607L13.7205 15.4122L10.3689 18.7638C10.2538 18.8744 10.1618 19.0068 10.0985 19.1534C10.0351 19.2999 10.0017 19.4576 10.0001 19.6173C9.99844 19.7769 10.0287 19.9353 10.089 20.0831C10.1494 20.2309 10.2386 20.3652 10.3515 20.4781C10.4644 20.591 10.5987 20.6802 10.7465 20.7406C10.8943 20.8009 11.0527 20.8312 11.2123 20.8296C11.372 20.8279 11.5297 20.7945 11.6763 20.7311C11.8228 20.6678 11.9552 20.5758 12.0658 20.4607L15.4174 17.1091L18.7689 20.4607C18.8795 20.5758 19.012 20.6678 19.1585 20.7311C19.3051 20.7945 19.4628 20.8279 19.6224 20.8296C19.7821 20.8312 19.9405 20.801 20.0883 20.7406C20.2361 20.6803 20.3704 20.591 20.4833 20.4781C20.5962 20.3652 20.6854 20.2309 20.7457 20.0831C20.8061 19.9353 20.8363 19.7769 20.8347 19.6173C20.8331 19.4577 20.7996 19.2999 20.7363 19.1534C20.6729 19.0068 20.581 18.8744 20.4658 18.7638L17.1143 15.4122L20.4658 12.0607C20.6392 11.8921 20.7577 11.6751 20.8056 11.4381C20.8536 11.2011 20.8288 10.9551 20.7345 10.7324C20.6403 10.5097 20.481 10.3206 20.2774 10.19C20.0739 10.0595 19.8357 9.99339 19.5939 10.0005C19.2822 10.0098 18.9863 10.1401 18.7689 10.3638L15.4174 13.7154L12.0658 10.3638C11.9539 10.2487 11.8199 10.1573 11.672 10.0949C11.524 10.0325 11.3651 10.0004 11.2045 10.0005V10.0005Z"
                    fill="#fff"
                  />
                </svg>
              </div>
              <img
                className="whatsApp_img"
                src={whatsAppImg}
                alt=""
                onClick={() =>
                  !isDragging ? window.open(`${promotions.Whatsapp.url}`) : {}
                }
              />
            </div>
          </Draggable>
        )}
      </BetPopoverContext.Provider>
    </IonApp>
  )
}
export default withIonLifeCycle(connectApp()(Sentry.withProfiler(App)))
